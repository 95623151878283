import './assets/css/App.css';
import IcLogo from './assets/image/logo.png';
import IcLogow from './assets/image/logo_w.png';
import IcS21 from './assets/image/s2_1.png';
import IcS22 from './assets/image/s2_2.png';
import IcS23 from './assets/image/s2_3.png';
import IcS24 from './assets/image/s2_4.png';
import {useCallback, useEffect, useState} from "react";
import Lottie from "lottie-react";
import Lottie_mov1 from './assets/lottie/main.json';
import Lottie_mov2 from './assets/lottie/smartphone.json';
import {GoogleMap, useJsApiLoader, MarkerF} from '@react-google-maps/api';
import axios from "axios";

function App() {

    const [isMenuChange, setIsMenuChange] = useState(false);
    const [fname, setFname] = useState('');
    const [femail, setFemail] = useState('');
    const [ftel, setFtel] = useState('');
    const [ftitle, setFtitle] = useState('');
    const [fcontents, setFcontents] = useState('');
    const [map, setMap] = useState(null);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsMenuChange(true);
        } else {
            setIsMenuChange(false);
        }
    };

    const center = {
        lat: 37.4797262, lng: 126.877030
    };

    const OPTIONS = {
        minZoom: 4,
        maxZoom: 18,
    }

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyB-SJgNW0XQFueXWknIdqCuAyV1inMFOL0",
    });

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map);
    }, []);

    const onUnmount = useCallback((map) => {
        setMap(null);
    }, []);


    const checkEmail = (value) => {
        return /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/.test(value);
    }

    const checkTel = (value) => {
        return /[0-9]{8,11}$/.test(value.replace(/-/g, ""));
    }

    const sendContactus = () => {
        let rtnvalue = {
            error: false,
            message: ''
        };

        if(!rtnvalue.error && !fname) {
            rtnvalue.error = true;
            rtnvalue.message = "성명을 입력해 주세요.";
        }

        if(!rtnvalue.error && !ftel) {
            rtnvalue.error = true;
            rtnvalue.message = "연락처를 입력해 주세요.";
        } else if(ftel && !checkTel(ftel)) {
            rtnvalue.error = true;
            rtnvalue.message = "연락처 형식이 아닙니다.";
        }

        if(!rtnvalue.error && femail) {
            if(!checkEmail(femail)) {
                rtnvalue.error = true;
                rtnvalue.message = "이메일 형식이 아닙니다.";
            }
        }

        if(!rtnvalue.error) {
            try {
                const headers = {
                    'Context-Type': "application/json"
                }
                axios.post('/contactus/add', {name: fname, tel: ftel, email: femail, title: ftitle, contents: fcontents}, {headers}).then(response => {
                    const data = response.data;
                    if (data.status === 200) {
                        window.alert("상담신청이 완료되었습니다.\n빠른 시일안에 연락드리겠습니다.");
                    } else {
                        window.alert(data.message);
                    }
                });
            } catch (e) {
                window.alert("전송중 에러가 발생했습니다.\n잠시후 다시 진행해주세요.");
            }
        }

        if(rtnvalue.error) {
            window.alert(rtnvalue.message);
        }

    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll); //clean up
        };
    }, []);

    return (
        <div className="App">
            <header className={isMenuChange ? 'showMenu' : ''}>
                <div className="header w1200">
                    <a href="#" class="logo"><h1><span>민트웨일</span>{isMenuChange ?
                        <img src={IcLogo} className="Logo" alt="로고"/> : <img src={IcLogow} className="Logo" alt="로고"/>}
                    </h1></a>
                    <nav id="main_nav">
                        <ul>
                            <li><a href="#section1"><span>민트웨일에 대해서</span></a></li>
                            <li><a href="#section2"><span>주요 솔루션</span></a></li>
                            <li><a href="#section3"><span>서비스 절차</span></a></li>
                            <li><a href="#section4"><span>상담요청</span></a></li>
                            <li><a href="#section5"><span>오시는길</span></a></li>
                        </ul>
                    </nav>
                </div>
            </header>
            <section id="section1">
                <div className="section section1 w1200">
                    <h2><span>민트웨일에 대해서</span></h2>
                    <span className="section1_info">
                        아이디어만 가져오세요.
                        <strong>빠르고 정확하게 구현합니다.</strong>
                        아이디어를 딱 맞는 솔루션을 적용하여,<br/>고객님에게 딱 맞는 모바일 서비스를 제공합니다.
                    </span>
                    <div className="smartBox">
                        <Lottie animationData={Lottie_mov1}/>
                    </div>
                </div>
            </section>
            <section id="section2">
                <div className="section section2 w1200">
                    <h2><span>주요 솔루션</span></h2>
                    <span className="section_info">
                        민트웨일은 <strong>다양한 솔루션</strong>을 제공합니다.
                    </span>
                    <ul>
                        <li>
                            <div className="inBox">
                                <span className="intitle">SOLUTION 1</span>
                                <img src={IcS21} alt="iot 삽화"/>
                                <span className="title">IOT 모바일 솔루션</span>
                                <span className="info">아두이노, 마이크로 PC등 IOT 기기를 활용한 서비스를 제공합니다.</span>
                            </div>
                        </li>
                        <li>
                            <div className="inBox">
                                <span className="intitle">SOLUTION 2</span>
                                <img src={IcS22} alt="위치기반 삽화"/>
                                <span className="title">위치기반 모바일 솔루션</span>
                                <span className="info">모바일기기의 GPS, WIFI 등의 좌표정보를 활용한 서비스를 제공합니다.</span>
                            </div>
                        </li>
                        <li>
                            <div className="inBox">
                                <span className="intitle">SOLUTION 3</span>
                                <img src={IcS23} alt="견적중계 삽화"/>
                                <span className="title">견적중계 모바일 솔루션</span>
                                <span className="info">장비, 화물, 인력 등 중계에 특화된 서비스를 제공합니다.</span>
                            </div>
                        </li>
                        <li>
                            <div className="inBox">
                                <span className="intitle">SOLUTION 4</span>
                                <img src={IcS24} alt="견적중계 삽화"/>
                                <span className="title">경매 모바일 솔루션</span>
                                <span className="info">MQTT와 FCM을 활용한 서비스를 제공합니다.</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <br/>
            </section>
            <section id="section3">
                <div className="section section3 w1200">
                    <h2><span>서비스 절차</span></h2>
                    <span className="section_info">
                        민트웨일은 <strong>서비스 개발</strong>을 다음과 같이 진행합니다.
                    </span>
                    <div className="smartBox">
                        <Lottie animationData={Lottie_mov2}/>
                    </div>
                    <ul>
                        <li>
                            <div className="inBox">
                                <h3><span className="step">STEP 1</span></h3>
                                <span className="title">요구사항 정리</span>
                                <span className="info">고객님과의 상담을 통해 아이디어에 맞는 솔루션을 선택합니다.</span>
                            </div>
                        </li>
                        <li>
                            <div className="inBox">
                                <h3><span className="step">STEP 2</span></h3>
                                <span className="title">기획 및 디자인</span>
                                <span className="info">정리한 내용을 토대로 기획 후 디자인 작업을 진행합니다.</span>
                            </div>
                        </li>
                        <li>
                            <div className="inBox">
                                <h3><span className="step">STEP 3</span></h3>
                                <span className="title">서비스 개발</span>
                                <span className="info">기획 및 디자인을 토대로 서비스를 개발합니다.</span>
                            </div>
                        </li>
                        <li>
                            <div className="inBox">
                                <h3><span className="step">STEP 4</span></h3>
                                <span className="title">서비스 오픈</span>
                                <span className="info">개발 검수 후 모바일 서비스를 오픈합니다.</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section id="section4">
                <div className="section section4 w1200">
                    <h2><span>상담요청</span></h2>
                    <span className="section_info">
                        민트웨일은 <strong>다양한 아이디어</strong>를 기다리고 있습니다.
                    </span>
                    <div className="goForm">
                        <label>성명</label>
                        <input id="f_name" name="f_name" placeholder="성명을 입력해 주세요." onChange={(t) => {
                            setFname(t.target.value);
                        }}/>
                        <label>연락처</label>
                        <input id="f_tel" name="f_tel" placeholder="연락처를 입력해 주세요." onChange={(t) => {
                            setFtel(t.target.value);
                        }}/>
                        <label>이메일</label>
                        <input id="f_email" name="f_email" placeholder="이메일을 입력해 주세요." onChange={(t) => {
                            setFemail(t.target.value);
                        }}/>
                        <label>제목</label>
                        <input id="f_title" name="f_title" placeholder="제목을 입력해 주세요." onChange={(t) => {
                            setFtitle(t.target.value);
                        }}/>
                        <label>내용</label>
                        <textarea id="f_contents" name="f_contents" placeholder="내용을 입력해 주세요." onChange={(t) => {
                            setFcontents(t.target.value);
                        }}/>
                    </div>
                    <button onClick={() => {
                        sendContactus();
                    }}><span>서비스 상담요청</span></button>
                </div>
            </section>
            <section id="section5">
                <div className="section section5 w1200">
                    <h2><span>오시는길</span></h2>
                    <span className="section_info">
                        민트웨일은 <strong>대성 디폴리스 A동 1108-1호</strong>에 있습니다.
                    </span>
                    <div id="setMap">
                        {isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={{
                                    width: '100%',
                                    height: '500px'
                                }}
                                center={center}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                                options={OPTIONS}
                            >
                                <MarkerF position={center}/>
                            </GoogleMap>
                        ) : <></>}
                    </div>
                </div>
            </section>
            <footer>
                <div className="footer w1200">
                    <dl>
                        <dt><span>회사명</span></dt>
                        <dd>민트웨일</dd>
                        <dt><span>대표자</span></dt>
                        <dd>조영학</dd>
                        <dt><span>전화</span></dt>
                        <dd>070-4323-3380</dd>
                        <dt><span>사업자등록증</span></dt>
                        <dd>779-05-02337</dd>
                        <dt><span>이메일</span></dt>
                        <dd>mintwhale.dev@gmail.com</dd>
                        <dt><span>주소</span></dt>
                        <dd>서울특별시 금천구 서부샛길 606 대성 디폴리스 지식산업센터 A동 1108-1호</dd>
                    </dl>
                    <span className="copy">
                &copy; 2023 by Mintwhale.kr
            </span>
                </div>
            </footer>
        </div>
    );
}

export default App;
